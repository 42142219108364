<template>
  <div>
    <b-container class="mt-4">
      <b-card header-class="header">
        <template #header>
          <label v-text="'Yarışma Bilgileri'" class="mr-2 text-white"></label>
          <b-button variant="primary" to="/competitions" class="ml-4"
            >Geri</b-button
          >
          <b-button
            variant="dark"
            @click="getAddClubGroupPage"
            class="ml-4"
            v-if="hasRole(['club']) && !form.endingDate"
          >
            Yarışma Grubu Oluştur
          </b-button>
          <b-button
            variant="dark"
            @click="getAddFederationGroupPage"
            class="ml-4"
            v-if="hasRole(['federation-locked']) && !form.endingDate"
          >
            Yarışma Grubu Oluştur
          </b-button>
       
          <b-button v-if="hasRole(['individual'])" class="ml-4" variant="dark" :to="{
            name: 'IndividualCompetitionGroupsList',
            params: { id: $route.params.id },
          }">
           Ferdi Yarışma Gruplarım
          </b-button>
          <b-button v-if="hasRole(['individual'])" class="ml-4" variant="primary" :to="{
            name: 'JoinIndividualGroup',
            params: { id: $route.params.id },
          
            
          }">
            Yarışma Grubuna Katıl
          </b-button>
          <b-button
            variant="dark"
            @click="getAddIndividualGroupPage"
            class="ml-4"
            v-if="hasRole(['individual']) && !form.endingDate"
          >
            Yarışma Grubu Oluştur
          </b-button>
          <b-button
            variant="dark"
            @click="getClubGroupPage"
            class="ml-4"
            v-if="hasRole(['club'])"
          >
            Yarışma Gruplarını Gör
          </b-button>
          <b-button
            variant="dark"
            @click="addFederationGroupPage"
            class="ml-4"
            v-if="hasRole(['federation'])"
            >Personel Gönder</b-button
          >
          <b-button
            variant="dark"
            @click="getGroupPage"
            class="ml-4"
            v-if="hasRole(['federation'])"
            >Yarışma Gruplarını Getir</b-button
          >
          <b-button
            variant="dark"
            @click="getRefereeMatchesPage"
            class="ml-4"
            v-if="hasRole(['sportsReferee'])"
            >Yarışma Sonuçlarını Gir</b-button
          >
        </template>

        <b-container class="mt-4">
          <b-form>
            <b-row>
              <b-col>
                <b-row>
                  <b-col class="mt-4 text-danger font-weight-bold">
                    Tarih Saat :
                  </b-col>
                  <b-col class="mt-4 font-weight-bold">
                    {{
                      form.createdAt
                        ? form.createdAt
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join(".") +
                          " " +
                          form.createdAt
                            .split("T")[1]
                            .split(".")[0]
                            .replace(":", ".")
                        : ""
                    }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="mt-4 text-danger font-weight-bold"
                    >Lokasyon :</b-col
                  >
                  <b-col class="mt-4 font-weight-bold">{{
                    locationOptions.find((e) => e.value == form.location).text
                  }}</b-col>
                </b-row>
                <b-row>
                  <b-col class="mt-4 text-danger font-weight-bold">Tür :</b-col>
                  <b-col class="mt-4 font-weight-bold">{{
                    form.election.title
                  }}</b-col>
                </b-row>
                <b-row>
                  <b-col class="mt-4 text-danger font-weight-bold"
                    >Branş :</b-col
                  >
                  <b-col class="mt-4 font-weight-bold">{{
                    getBranch(form.branch)
                  }}</b-col>
                </b-row>
                <b-row>
                  <b-col class="mt-4 text-danger font-weight-bold"
                    >Kategori :</b-col
                  >
                  <b-col class="mt-4 font-weight-bold">{{
                    getCategory(form.category)
                  }}</b-col>
                </b-row>
                <b-row>
                  <b-col class="mt-4 text-danger font-weight-bold">Adı :</b-col>
                  <b-col class="mt-4 font-weight-bold">{{ form.name }}</b-col>
                </b-row>
                <b-row>
                  <b-col class="mt-4 text-danger font-weight-bold"
                    >Katılan Kulüp Sayısı:</b-col
                  >
                  <b-col class="mt-4 font-weight-bold">{{
                    form.countryCount
                  }}</b-col>
                </b-row>
                <b-row>
                  <b-col class="mt-4 text-danger font-weight-bold"
                    >Sporcu Sayı :</b-col
                  >
                  <b-col class="mt-4 font-weight-bold">{{
                    form.numberOfAthletesParticipating
                  }}</b-col>
                </b-row>
                <b-row>
                  <b-col class="mt-4 text-danger font-weight-bold"
                    >Sporcu Yaş Sınırı(Alt) :</b-col
                  >
                  <b-col class="mt-4 font-weight-bold">{{
                    form.lowerAgeLimit
                  }}</b-col>
                </b-row>
                <b-row>
                  <b-col class="mt-4 text-danger font-weight-bold"
                    >Sporcu Yaş Sınırı(Üst) :</b-col
                  >
                  <b-col class="mt-4 font-weight-bold">{{
                    form.upperAgeLimit
                  }}</b-col>
                </b-row>
                <b-row>
                  <b-col class="mt-4 text-danger font-weight-bold">Yer :</b-col>
                  <b-col class="mt-4 font-weight-bold">{{ form.place }}</b-col>
                </b-row>
                <b-row>
                  <b-col class="mt-4 text-danger font-weight-bold"
                    >Başlangıç Tarih :</b-col
                  >
                  <b-col class="mt-4 font-weight-bold">{{
                    form.startingDate.split("-").reverse().join(".")
                  }}</b-col>
                </b-row>
                <b-row>
                  <b-col class="mt-4 text-danger font-weight-bold"
                    >Bitiş Tarih :</b-col
                  >
                  <b-col class="mt-4 font-weight-bold">{{
                    form.endingDate.split("-").reverse().join(".")
                  }}</b-col>
                </b-row>
                <b-row>
                  <b-col class="mt-4 text-danger font-weight-bold"
                    >Sonuç Girilebilir :</b-col
                  >
                  <b-col class="mt-4 font-weight-bold">{{
                    form.resultCanBeEntered ? "Evet" : "Hayır"
                  }}</b-col>
                </b-row>
                <b-row>
                  <b-col class="mt-4 text-danger font-weight-bold"
                    >Açıklama :</b-col
                  >
                  <b-col class="mt-4 font-weight-bold">{{
                    form.description
                  }}</b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </b-container>
      </b-card>
    </b-container>
  </div>
</template>


<script>
import locationOptions from "../../store/constants/locationOptions";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      locationOptions,
    };
  },
  computed: {
    ...mapGetters([
      "getSingleCompetition",
      "getManagementItems",
      "getSingleClub",
    ]),
    getBranchName: {
      get() {
        const branch = this.getSingleCompetition.branch;
        const managementItems = this.getManagementItems;
        return managementItems.find(
          (item) => item.id == branch
        )?.title;
      },
    },
    form: {
      get() {
        return this.getSingleCompetition;
      },
    },
  },
  created() {
    if (this.$route.params) {
      this.$store.dispatch("initSingleCompetition", this.$route.params.id);
      if(this.hasRole(["club"]))  this.$store.dispatch("initSingleClub", this.$store.getters.getUser.id);
      this.$store.dispatch("initManagement");
    }
  },
  mounted() {},
  methods: {
    hasRole(roles) {
      let vals = [];
      roles.forEach((role) => {
        if (role == "federation") {
          vals.push(this.$store.getters.checkToken.isFederation);
        } else if (role == "personel") {
          vals.push(this.$store.getters.checkToken.isPersonel);
        } else if (role == "club") {
          vals.push(this.$store.getters.checkToken.isClub);
        } else if (role == "provincialRepresentative") {
          vals.push(this.$store.getters.checkToken.isProvincialRepresentative);
        } else if (role == "individual") {
          vals.push(this.$store.getters.checkToken.isIndividual);
        } else if (role == "sportsReferee") {
          vals.push(this.$store.getters.checkToken.sportsReferee);
        }
      });
      return vals.includes(true);
    },
    clubIsAccredited() {
      const branch = this.$store.getters.getSingleCompetition.branch;
      const managementItems = this.$store.getters.getManagementItems;
      const branchName = managementItems.find(
        (item) => item.id == branch
      )?.title;

      if (!branchName) return false;

      const accreditationTypes = {
        Akarsu: "streamWaterLastAccreditedYear",
        Durgunsu: "stillWaterLastAccreditedYear",
        "Deniz Kanosu": "seaKayakingLastAccreditedYear",
        "Dragon Bot": "dragonBoatLastAccreditYear",
        Rafting: "raftingLastAccreditYear",
      };

      const accreditationType = accreditationTypes[branchName];
      if (!accreditationType) return false;

      const accreditYear = this.$store.getters.getSingleClub[accreditationType];
      const thisYear = new Date().getFullYear();
      const isValid = accreditYear && accreditYear == thisYear;

      return isValid;
    },

    addFederationGroupPage() {
      this.$router.push({
        name: "AddCompetitionFederationGroup",
        params: { id: this.$route.params.id },
      });
    },
    getAddClubGroupPage() {
      if (this.clubIsAccredited()) {
        this.$router.push({
          name: "AddCompetitionGroup",
          params: { id: this.$route.params.id },
        });
      } else {
        this.$store.dispatch("notification/setNotifications", [
          {
            title: "HATA!",
            body: "Seçilen yarışma için akreditasyonunuz yapılmamıştır. Lütfen federasyon ile iletişime geçin!",
            type: "danger",
          },
        ]);
      }
    },
    getRefereeMatchesPage() {
      this.$router.push({
        name: "Matches",
        params: { id: this.$route.params.id },
      });
    },
    getAddIndividualGroupPage() {
      this.$router.push({
        name: "AddIndividualCompetitionGroup",
        params: { id: this.$route.params.id },
      });
    },
    getAddFederationGroupPage() {
      this.$router.push({
        name: "AddFederationCompetitionGroup",
        params: { id: this.$route.params.id },
      });
    },
    getBranch(id) {
      var branch = this.getManagementItems.find((e) => e.id == id);
      if (branch != undefined) {
        return this.getManagementItems.find((e) => e.id == id).title;
      } else {
        return "";
      }
    },
    getClubGroupPage() {
      this.$router.push({
        name: "ClubCompetitionGroup",
        params: { id: this.$route.params.id },
      });
    },
    getGroupPage() {
      this.$router.push({
        name: "FederationGroups",
        params: { id: this.$route.params.id },
      });
    },
    getCategory(categories) {
      let items = this.getManagementItems.filter((e) => {
        return categories.split(";").find((k) => k == e.id);
      });
      return items.map((e) => e.title).join(", ");
    },
  },
};
</script>


<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>