module.exports = [
    { value: "", text: "Seçin" },
    {
        text: "Ulusal",
        value: "national",
    },
    {
        text: "Uluslararası",
        value: "international",
    },
];